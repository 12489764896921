/**
 * 成績表画面
 */
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import styled from 'styled-components';
import StoreIndices from './StoreIndicesContainer';
import StoreMonthlyIndices from './StoreMonthlyIndicesContainer';
import StoreCalendarIndices from './StoreCalendarIndicesContainer';
import ActivityIndicator from '../../../components/common/ActivityIndicator';
import { ErrorCommon, ERROR_TYPE_FAILED } from '../../../components/common/templates/ErrorCommon';
import ApiError from '../../../components/common/templates/ApiError';
import StoreHead from './components/common/StoreHead';
import StoreSide from './components/common/StoreSide';
import {
  changeSelectStore,
  changeSalesCustomizeConfigModalState,
  startFetchStoreSalesCustomizeItem,
  startFetchSalesCustomizeItem,
  startPostSalesCustomizeConfig,
  resetSalesCustomizeConfigModalState,
  startPostFiscalYearSettings,
  changeFiscalYearSettingsModalState,
  closeFiscalYearSettingsMoal,
  startFetchGroupFiscalYear,
} from '../../../modules/stores';
import { fetchKwsCandidate } from '../../../modules/kws';
import {
  SalesCustomizeItemList,
  SalesCustomizeItem,
  TaxSettingStatus,
} from '../../../typedef/api/StoreIndices';
import { actions as apiActions } from '../../../modules/storeMonthlyIndices/api';
import {
  judgeThisMonthSelector,
  judgeThisYearSelector,
  selectedStoreSelector,
  storesDataStateSelector,
} from '../../../selectors/storeSelector';
import { actions as existMonthResultListActions } from '../../../modules/storeIndices/existMonthResultList';
import { actions as taxSettingStatusActions } from '../../../modules/stores';
import {
  selectStore,
  changeDisplayedStoreList,
  changeSelectIndicesType,
  changeConfigDialogState,
  clearBannerType,
  clearTaxSettingStatus,
} from '../../../modules/stores';
import Templates from '../../../components/common/templates';
import { track } from '../../../modules/logging';
import { State as ReduxState } from '../../../modules';
import { StoresData, UserData } from '../../../modules/user';
import { Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import * as AkrCode from '../../../typedef/AkrCode';
import { StoreList as AllIndexStoreList } from '../../../typedef/api/AllIndex';
import { actions as allIndexActions } from '../../../modules/allIndex';
import {
  ApiState,
  API_STATE_STARTED,
  API_STATE_INITIAL,
  API_STATE_COMPLETED,
  API_STATE_FAILED,
} from '../../../typedef/api/Utility';
import { getErrorData, getReturnCode, assertUnreachable, isCOO } from '../../../helpers/util';
import { StoreList, FiscalYearSalesProgressListResponse } from '../../../typedef/api/StoreMonthlyIndices';
import { BatchProcessedDate } from '../../../typedef/BatchProcessedDate';
import { PresenceOrAbsenceOfSalesListResponse } from '../../../typedef/api/PresenceOrAbsenceOfSalesList';
import { returnCodes } from '../../../constants/mapi';
import ConfigModal from './components/common/ConfigModal';
import {
  SALES_CUSTOMIZE_ITEM_DESCRIPTION,
  INDICES_TYPE,
  HAS_NEWLINE_TOOLTIP,
  DESCRIPTION_WITH_BREAK,
} from './storesConstants';
import { FiscalYearSettingModal } from './components/common/FiscalYearSettingModal';
import { GroupFiscalYearResponse } from '../../../typedef/api/BudgetSetting';
import { genGaLog } from '../../../gaLogger';
import { airblue, disabledTextColor } from '../../../constants/colors';
import { getAuthErrorLink, getBasicStoreInfomationLink } from '../../../helpers/envHelper';
import { PlfGrant } from '../../../typedef/PlfGrant';
import { getCookie } from '../../../helpers/cookieHelper';
import { handyFaq } from '../../../constants/faqUrls';
import {
  SELECTED_INDICES_TYPE_KEY_NAME,
  IS_DISPLAYED_STORE_LIST,
  SELECTED_AKRCODE,
} from '../../../constants/LocalStorage';
import ExternalLink from '../../../icons/openLinkGray.svg';
import { assignedStoresSelector } from '../../../selectors/userDataSelector';
import { parser } from '../../../helpers/mclDate';
import { getDeflutStoreForStoreIndices } from '../../../helpers/util';
import { fetchBasicSettingStart } from '../../../modules/basicSetting';
import { BasicSetting as BasicSettingType } from '../../../typedef/api/BasicSetting';

type DispatchProps = {
  readonly changeStore: typeof existMonthResultListActions.changeStore;
  readonly fetchExistMonthResultList: typeof existMonthResultListActions.fetchExistMonthResultList;
  readonly selectAkrCode: typeof selectStore;
  readonly changeSelectDate: typeof allIndexActions.changeSelectDate;
  readonly track: typeof track;
  readonly fetchYearStoreList: typeof apiActions.fetchYearStoreList;
  readonly fetchYearAnalysis: typeof apiActions.fetchYearAnalysis;
  readonly changeSelectStore: typeof changeSelectStore;
  readonly changeDisplayedStoreList: typeof changeDisplayedStoreList;
  readonly changeSelectIndicesType: typeof changeSelectIndicesType;
  readonly changeSalesCustomizeConfigModalState: typeof changeSalesCustomizeConfigModalState;
  readonly startFetchStoreSalesCustomizeItem: typeof startFetchStoreSalesCustomizeItem;
  readonly startFetchSalesCustomizeItem: typeof startFetchSalesCustomizeItem;
  readonly startPostSalesCustomizeConfig: typeof startPostSalesCustomizeConfig;
  readonly resetSalesCustomizeConfigModalState: typeof resetSalesCustomizeConfigModalState;
  readonly toggleDialog: typeof changeConfigDialogState;
  readonly startPostFiscalYearSettings: typeof startPostFiscalYearSettings;
  readonly changeFiscalYearSettingsModalState: typeof changeFiscalYearSettingsModalState;
  readonly closeFiscalYearSettingsMoal: typeof closeFiscalYearSettingsMoal;
  readonly startFetchGroupFiscalYear: typeof startFetchGroupFiscalYear;
  readonly resetStoreMonthlyApiState: typeof apiActions.resetStoreMonthlyApiState;
  readonly clearBannerType: typeof clearBannerType;
  readonly fetchKwsCandidate: typeof fetchKwsCandidate;
  readonly fetchTaxSettingStatus: typeof taxSettingStatusActions.fetchTaxSettingStatusStart;
  readonly fetchBasicSetting: typeof fetchBasicSettingStart;
  readonly clearTaxSettingStatus: typeof clearTaxSettingStatus;
};
type StateProps = {
  readonly userData: UserData | null;
  readonly batchDate: BatchProcessedDate;
  readonly selectedStore?: StoresData | undefined;
  readonly storeDataState: ApiState<{
    dailyData: ReadonlyArray<StoresData & AllIndexStoreList>;
    monthlyData: ReadonlyArray<StoreList & StoresData>;
  }>;
  readonly stores: ReadonlyArray<StoresData>;
  readonly selectedYear?: number | undefined;
  readonly yearStoreListState: ApiState<FiscalYearSalesProgressListResponse>;
  readonly isDisplayedStoreList: boolean;
  readonly isSelectedThisMonth: boolean;
  readonly isSelectedThisYear: boolean;
  readonly selectedIndicesType: keyof typeof INDICES_TYPE;
  readonly selectedDate?: string | null;
  readonly storeSalesCustomizeItemApiState: ApiState<SalesCustomizeItemList>;
  readonly salesCustomizeItemApiState: ApiState<SalesCustomizeItemList>;
  readonly isOpenConfigModal: boolean;
  readonly isEditing: boolean;
  readonly isOpenDialog: boolean;
  readonly isOpenFiscalYearSettingModal: boolean;
  readonly fetchGroupFiscalYearInfoState: ApiState<GroupFiscalYearResponse>;
  readonly plfGrant?: PlfGrant;
  readonly lastUpdateDate?: string | null;
  readonly presenceOrAbsenceOfSalesListState: ApiState<PresenceOrAbsenceOfSalesListResponse>;
  readonly taxSettingStatus: ApiState<TaxSettingStatus>;
  readonly basicSetting: ApiState<BasicSettingType>;
};
type Props = Readonly<RouteComponentProps<{}> & DispatchProps & StateProps>;
class StoreContainer extends React.PureComponent<Props> {
  componentDidMount() {
    const {
      selectAkrCode,
      stores,
      fetchYearStoreList,
      changeSelectDate,
      batchDate,
      changeSelectStore,
      changeDisplayedStoreList,
      startFetchStoreSalesCustomizeItem,
      startFetchSalesCustomizeItem,
      startFetchGroupFiscalYear,
      changeSelectIndicesType,
      fetchKwsCandidate,
      presenceOrAbsenceOfSalesListState,
      fetchTaxSettingStatus,
      fetchBasicSetting,
    } = this.props;
    const getIsDisplayedStoreList = localStorage.getItem(IS_DISPLAYED_STORE_LIST);

    if (getIsDisplayedStoreList != null) {
      changeDisplayedStoreList(getIsDisplayedStoreList === 'true');
    }

    const akrCode = getDeflutStoreForStoreIndices(
      stores.map(store => store.akrCode.toString()),
      presenceOrAbsenceOfSalesListState.type === API_STATE_COMPLETED
        ? presenceOrAbsenceOfSalesListState.payload.presenceOrAbsenceOfSalesList.defaultStore
        : undefined
    );

    if (akrCode != null) {
      selectAkrCode(akrCode);
    }

    changeSelectStore(new Set(stores.map(store => store.akrCode)));

    changeSelectDate({
      dateFrom: parser.fromDateObject(batchDate).startOf('month').toLocalDateObj(),
      dateTo: batchDate,
    });

    let selectedIndicesType: keyof typeof INDICES_TYPE = 'calendar';
    const localStorageSelectedIndicesType = localStorage.getItem(SELECTED_INDICES_TYPE_KEY_NAME);
    //ローカルストレージに初期表示する表示タイプが存在する場合にそちらを初期表示する
    if (localStorageSelectedIndicesType != null) {
      selectedIndicesType = localStorageSelectedIndicesType as keyof typeof INDICES_TYPE;
    }

    changeSelectIndicesType(selectedIndicesType);

    fetchYearStoreList();
    startFetchGroupFiscalYear();
    startFetchStoreSalesCustomizeItem(akrCode);
    startFetchSalesCustomizeItem();
    fetchKwsCandidate();
    fetchBasicSetting();
    fetchTaxSettingStatus();

    this.props.track(this.genComponentDidMountLog('store_indices', akrCode, selectedIndicesType));
  }

  componentWillUnmount() {
    this.props.clearBannerType();
    this.props.clearTaxSettingStatus();
  }

  genComponentDidMountLog = (viewName: string, akrCode: string, indicesType: keyof typeof INDICES_TYPE) => {
    const cookieData = getCookie('influxData');
    let vos: string | undefined;
    let lid: string | undefined;
    let viaPromoFlg: string | undefined;
    if (cookieData != null) {
      const cookieDataJson = JSON.parse(cookieData);
      vos = cookieDataJson.vos;
      lid = cookieDataJson.lid;
      viaPromoFlg = cookieDataJson.via_promo_flg;
    }
    return genGaLog(
      viewName,
      viewName,
      'on_load',
      {},
      { selected_indices_type: INDICES_TYPE[indicesType].logName },
      'load',
      akrCode,
      vos,
      lid,
      viaPromoFlg
    );
  };

  _handleClickSetting = () => {
    this.props.track(_genOpenSettingLog());
  };

  _handleClickConfigModalOpener = () => {
    const { changeSalesCustomizeConfigModalState, isOpenConfigModal, track } = this.props;

    if (!isOpenConfigModal) {
      changeSalesCustomizeConfigModalState();
      track(_genConfigModalLoadLog());
    }
  };

  onCloseConfigModal = () => {
    const { isEditing, isOpenDialog, resetSalesCustomizeConfigModalState, toggleDialog, track } = this.props;
    if (isEditing && !isOpenDialog) {
      toggleDialog();
    } else {
      resetSalesCustomizeConfigModalState();
    }
    track(_genConfigModalCloseLog());
  };

  onConfigSubmit = (config: ReadonlyArray<SalesCustomizeItem>) => {
    const { track, startPostSalesCustomizeConfig } = this.props;
    startPostSalesCustomizeConfig(config);
    track(_genConfigModalSubmitLog());
  };

  onFiscalYearSettingSubmit = (startMonth: number) => {
    const { startPostFiscalYearSettings, track } = this.props;
    startPostFiscalYearSettings(startMonth);
    track(_genFiscalYearSettingModalSubmitLog());
  };

  _handleClickFiscalYearSettingsMoalOpener = () => {
    const { changeFiscalYearSettingsModalState, isOpenFiscalYearSettingModal } = this.props;

    if (!isOpenFiscalYearSettingModal) {
      changeFiscalYearSettingsModalState();
    }
  };

  onCloseFiscalYearSettingsModal = () => {
    const { closeFiscalYearSettingsMoal, track } = this.props;
    closeFiscalYearSettingsMoal();
    track(_genCloseFiscalYearModalLog());
  };

  onActionLoad = () => {
    const { track } = this.props;
    track(_genFiscalYearSettingModalLoadLog());
  };

  switchContents = (description: {
    [key in keyof typeof SALES_CUSTOMIZE_ITEM_DESCRIPTION]: React.ReactNode;
  }) => {
    const {
      storeDataState,
      yearStoreListState,
      selectedIndicesType,
      isOpenConfigModal,
      salesCustomizeItemApiState,
      isOpenFiscalYearSettingModal,
      fetchGroupFiscalYearInfoState,
      stores,
    } = this.props;

    let startMonth: number = 1;
    if (fetchGroupFiscalYearInfoState.type === API_STATE_COMPLETED) {
      // 年度開始月が設定されている場合に設定する(未設定の場合は1月をデフォルトとする)
      if (fetchGroupFiscalYearInfoState.payload.fiscalYearInfo.startMonth != null) {
        startMonth = fetchGroupFiscalYearInfoState.payload.fiscalYearInfo.startMonth;
      }
    }
    switch (storeDataState.type) {
      // 呼び出し元でAPI_STATE_COMPLETEDの場合しかこちらに来ないので、API_STATE_COMPLETEDのみ定義している
      case API_STATE_COMPLETED:
        if (yearStoreListState.type === API_STATE_FAILED && selectedIndicesType === 'monthly') {
          return (
            <Templates.Center>
              {yearStoreListState.type === API_STATE_FAILED &&
              getErrorData(yearStoreListState.error) != null &&
              getReturnCode(yearStoreListState.error) === returnCodes.replaceGroupId ? (
                <ErrorCommon
                  type={ERROR_TYPE_FAILED}
                  msg={
                    '店舗グループ統廃合によるデータ移行処理中のため、\nデータを表示できません。\n　\nお手数ですが、時間をおいて再度お試しください。'
                  }
                />
              ) : (
                <ApiError />
              )}
            </Templates.Center>
          );
        }
        return (
          <React.Fragment>
            <ContentContainer>
              <StoreIndicesContent
                selectedIndicesType={selectedIndicesType}
                handleClickStoreListItem={this.handleClickStoreListItem}
                handleClickConfigModalOpener={this._handleClickConfigModalOpener}
                isSingleStore={stores.length === 1}
              />
            </ContentContainer>
            {isOpenConfigModal && salesCustomizeItemApiState.type === 'API_STATE_COMPLETED' && (
              <ConfigModal
                onClose={this.onCloseConfigModal}
                config={salesCustomizeItemApiState.payload}
                description={description}
                onSubmit={this.onConfigSubmit}
              />
            )}
            {isOpenFiscalYearSettingModal && (
              <FiscalYearSettingModal
                onSubmit={this.onFiscalYearSettingSubmit}
                onCancel={this.onCloseFiscalYearSettingsModal}
                onLoad={this.onActionLoad}
                isCancellable={true}
                currentStartMonth={startMonth}
              />
            )}
          </React.Fragment>
        );
      default:
        assertUnreachable();
        return <React.Fragment />;
    }
  };

  // when click side bar store item
  handleClickStoreListItem = (store: { readonly akrCode: AkrCode.T; readonly storeName?: string }): void => {
    const { akrCode, storeName } = store;
    const {
      changeStore,
      fetchExistMonthResultList,
      selectAkrCode,
      fetchYearAnalysis,
      startFetchStoreSalesCustomizeItem,
      selectedYear,
      isDisplayedStoreList,
      selectedDate,
      selectedIndicesType,
      clearBannerType,
    } = this.props;

    const log = isDisplayedStoreList
      ? _genHandleClickStoreListItemLog(akrCode, storeName, selectedIndicesType)
      : _genChangeStoreLog(akrCode, storeName, selectedIndicesType);

    selectAkrCode(akrCode, log);
    changeStore();
    startFetchStoreSalesCustomizeItem(akrCode);
    if (selectedDate != null) {
      fetchExistMonthResultList(AkrCode.of(akrCode), parseInt(selectedDate.substr(0, 4)));
    }

    if (selectedYear != null) {
      fetchYearAnalysis({ akrCode: AkrCode.of(akrCode), fiscalYear: selectedYear });
    }

    clearBannerType();
    localStorage.setItem(SELECTED_AKRCODE, akrCode);
  };

  _handleChangeDisplayedStoreList = (isDisplay: boolean) => {
    const { changeDisplayedStoreList, track } = this.props;
    localStorage.setItem(IS_DISPLAYED_STORE_LIST, `${isDisplay}`);
    track(_genChangeDisplayedStoreListLog(isDisplay));
    changeDisplayedStoreList(isDisplay);
  };

  // 天気ツールチップ、項目カスタマイズ天気説明文言を生成（リンクがあるため文言のみにできずconstants/storesから切り出し）
  generateWeatherText = (selectedAkrCode?: AkrCode.T) => {
    const { plfGrant } = this.props;
    if (selectedAkrCode != null) {
      return (
        <div>
          各日付の20時時点の天気を表示しています。当日とその翌日は予報を表示しています。
          <br />
          <div>
            天気データを取得するには、「
            <LinkText
              target="_blank"
              href={
                plfGrant != null && isCOO(plfGrant)
                  ? getBasicStoreInfomationLink(selectedAkrCode)
                  : getAuthErrorLink()
              }
              onClick={() => {
                const log = _genClickWeatherLog(selectedAkrCode);
                this.props.track(log);
              }}
            >
              店舗基本情報
              <StyledExternalLink />
            </LinkText>
            」より地図設定を行なってください。
          </div>
          <Annotation>※地図設定はAirIDの管理者アカウントでのみ設定が可能です。</Annotation>
        </div>
      );
    }
    return undefined;
  };
  generateHasNewlineConfigItemDescription = (paramName: string): React.ReactElement => {
    return (
      <div>
        {HAS_NEWLINE_TOOLTIP[paramName].description}
        <br />
        <br />
        {HAS_NEWLINE_TOOLTIP[paramName].annotation != null ? (
          HAS_NEWLINE_TOOLTIP[paramName].annotation.map(annotation => (
            <React.Fragment key={annotation}>
              {annotation}
              <br />
            </React.Fragment>
          ))
        ) : (
          <React.Fragment />
        )}
      </div>
    );
  };

  generateConfigItemDescription = (paramName: string): React.ReactElement => {
    return (
      <div>
        {DESCRIPTION_WITH_BREAK[paramName].description}
        {DESCRIPTION_WITH_BREAK[paramName].annotation.map(annotation => (
          <Annotation key={annotation}>{annotation}</Annotation>
        ))}
        {DESCRIPTION_WITH_BREAK[paramName].needDetail && (
          <Annotation>
            詳細は
            <LinkText target="_blank" href={handyFaq}>
              こちら
            </LinkText>
            からご確認ください。
          </Annotation>
        )}
      </div>
    );
  };

  description = {
    ...SALES_CUSTOMIZE_ITEM_DESCRIPTION,
    weather: this.generateWeatherText(this.props.selectedStore?.akrCode),
    lateServeRate: this.generateConfigItemDescription('lateServeRate'),
    lateServeVisitorNum: this.generateConfigItemDescription('lateServeVisitorNum'),
    firstFoodAverageServingTime: this.generateConfigItemDescription('firstFoodAverageServingTime'),
    firstDrinkAverageServingTime: this.generateConfigItemDescription('firstDrinkAverageServingTime'),
    foodLateServeCountAverage: this.generateConfigItemDescription('foodLateServeCountAverage'),
    drinkLateServeCountAverage: this.generateConfigItemDescription('drinkLateServeCountAverage'),
    alertTimeDelayItemCount: this.generateConfigItemDescription('alertTimeDelayItemCount'),
    personTimeGrossIncome: this.generateConfigItemDescription('personTimeGrossIncome'),
    recommendSuccessCountAverage: this.generateConfigItemDescription('recommendSuccessCountAverage'),
    repeaterRate: this.generateConfigItemDescription('repeaterRate'),
    repeaterGroupRate: this.generateConfigItemDescription('repeaterGroupRate'),
    theoryCost: this.generateConfigItemDescription('theoryCost'),
    theoryCostRate: this.generateConfigItemDescription('theoryCostRate'),
    foodSales: this.generateConfigItemDescription('foodSales'),
    drinkSales: this.generateConfigItemDescription('drinkSales'),
    alcoholDrinkSales: this.generateConfigItemDescription('alcoholDrinkSales'),
    softDrinkSales: this.generateConfigItemDescription('softDrinkSales'),
    courseSales: this.generateConfigItemDescription('courseSales'),
    takeoutSales: this.generateConfigItemDescription('takeoutSales'),
    deliverySales: this.generateConfigItemDescription('deliverySales'),
    businessDayCount: this.generateHasNewlineConfigItemDescription('businessDayCount'),
    shiftEstimateLaborCost: this.generateConfigItemDescription('shiftEstimateLaborCost'),
  };

  render() {
    const {
      isDisplayedStoreList,
      selectedStore,
      storeDataState,
      selectedIndicesType,
      isSelectedThisMonth,
      isSelectedThisYear,
      lastUpdateDate,
      taxSettingStatus,
      basicSetting,
      userData,
      track,
    } = this.props;
    switch (storeDataState.type) {
      case API_STATE_INITIAL:
      case API_STATE_STARTED:
      default:
        return (
          <Templates.Center>
            <ActivityIndicator />
          </Templates.Center>
        );
      case API_STATE_COMPLETED:
        return (
          <React.Fragment>
            <MainWrapper>
              <StoreSide
                isDisplayedStoreList={isDisplayedStoreList}
                storeData={
                  selectedIndicesType === 'monthly'
                    ? storeDataState.payload.monthlyData.map(md => ({
                        storeName: md.storeName,
                        salesGoalRate: md.salesGoalRate != null ? md.salesGoalRate : undefined,
                        akrCode: md.akrCode,
                      }))
                    : storeDataState.payload.dailyData.map(dd => ({
                        storeName: dd.storeName,
                        salesGoalRate: dd.salesGoalRate != null ? dd.salesGoalRate : undefined,
                        akrCode: dd.akrCode,
                      }))
                }
                isSelectedThisRange={
                  //本年度・当月以外は目標達成率を非表示
                  selectedIndicesType === 'monthly' ? isSelectedThisYear : isSelectedThisMonth
                }
                selectedStore={selectedStore}
                onClickStore={this.handleClickStoreListItem}
                isMonthlyIndices={selectedIndicesType === 'monthly'}
                track={track}
                selectedIndicesType={selectedIndicesType}
              />
              <StoreDataContainer hasStoreList={isDisplayedStoreList} id="store_info">
                <StoreHead
                  selectedStore={selectedStore}
                  isDisplayedStoreList={isDisplayedStoreList}
                  storeData={
                    selectedIndicesType === 'monthly'
                      ? storeDataState.payload.monthlyData.map(md => ({
                          storeName: md.storeName,
                          salesGoalRate: md.salesGoalRate != null ? md.salesGoalRate : undefined,
                          akrCode: md.akrCode,
                        }))
                      : storeDataState.payload.dailyData.map(dd => ({
                          storeName: dd.storeName,
                          salesGoalRate: dd.salesGoalRate != null ? dd.salesGoalRate : undefined,
                          akrCode: dd.akrCode,
                        }))
                  }
                  isSelectedThisRange={
                    selectedIndicesType === 'monthly' ? isSelectedThisYear : isSelectedThisMonth
                  }
                  isMonthlyIndices={selectedIndicesType === 'monthly'}
                  changeDisplayedStoreList={this._handleChangeDisplayedStoreList}
                  handleClickStoreListItem={this.handleClickStoreListItem}
                  handleClickFiscalYearSettingsMoalOpener={this._handleClickFiscalYearSettingsMoalOpener}
                  handleClickSetting={this._handleClickSetting}
                  lastUpdateDate={lastUpdateDate != null ? lastUpdateDate : undefined}
                  onClickTooltip={() => track(_genTooltipFaqLog(selectedIndicesType))}
                  selectedIndicesType={selectedIndicesType}
                  tracker={track}
                  taxSettingStatus={taxSettingStatus}
                  basicSetting={basicSetting}
                  userData={userData}
                />
                <ContentWrapper hasStoreList={isDisplayedStoreList}>
                  {this.switchContents(this.description)}
                </ContentWrapper>
              </StoreDataContainer>
            </MainWrapper>
          </React.Fragment>
        );
    }
  }
}

const StoreIndicesContent = ({
  selectedIndicesType,
  handleClickStoreListItem,
  handleClickConfigModalOpener,
  isSingleStore,
}: {
  selectedIndicesType: keyof typeof INDICES_TYPE;
  handleClickStoreListItem: (store: { readonly akrCode: AkrCode.T; readonly storeName?: string }) => void;
  handleClickConfigModalOpener: () => void;
  isSingleStore: boolean;
}): React.ReactElement => {
  switch (selectedIndicesType) {
    case 'daily':
      return (
        <StoreIndices
          handleClickStoreListItem={handleClickStoreListItem}
          handleClickConfigModalOpener={handleClickConfigModalOpener}
        />
      );
    case 'monthly':
      return (
        <StoreMonthlyIndices
          handleClickStoreListItem={handleClickStoreListItem}
          handleClickConfigModalOpener={handleClickConfigModalOpener}
        />
      );
    case 'calendar':
      return (
        <StoreCalendarIndices
          handleClickStoreListItem={handleClickStoreListItem}
          isSingleStore={isSingleStore}
        />
      );
    default:
      assertUnreachable();
      return <React.Fragment />;
  }
};

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    userData: state.user.data,
    batchDate: state.uiConfig.batchProcessedDate,
    selectedStore: selectedStoreSelector(state),
    selectedYear: state.storeMonthlyIndices.ui.selectYear,
    storeDataState: storesDataStateSelector(state),
    stores: assignedStoresSelector(state),
    yearStoreListState: state.storeMonthlyIndices.api.fetchYearStoreListState,
    isDisplayedStoreList: state.stores.isDisplayedStoreList,
    isSelectedThisMonth: judgeThisMonthSelector(state),
    isSelectedThisYear: judgeThisYearSelector(state),
    selectedIndicesType: state.stores.selectedIndicesType,
    selectedDate: state.storeIndices.existMonthResultList.selectedMonth,
    storeSalesCustomizeItemApiState: state.stores.storeSalesCustomizeItem,
    salesCustomizeItemApiState: state.stores.salesCustomizeItem,
    isOpenConfigModal: state.stores.isOpenSalesCustomizeConfigModal,
    isOpenDialog: state.stores.isOpenConfigDialog,
    isEditing: state.stores.isEditingConfig,
    isOpenFiscalYearSettingModal: state.stores.isOpenFiscalYearSettingsModal,
    fetchGroupFiscalYearInfoState: state.stores.fetchGroupFiscalYearInfoState,
    plfGrant: state.user.data != null ? state.user.data.plfGrant : undefined,
    lastUpdateDate: state.uiConfig.batchProcessLastFinishDatetime,
    presenceOrAbsenceOfSalesListState: state.stores.presenceOrAbsenceOfSalesListState,
    taxSettingStatus: state.stores.taxSettingStatus,
    basicSetting: state.basicSetting.data,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    changeStore: () => dispatch(existMonthResultListActions.changeStore()),
    fetchExistMonthResultList: (akrCode: AkrCode.T, year: number) =>
      dispatch(existMonthResultListActions.fetchExistMonthResultList(akrCode, year)),
    selectAkrCode: bindActionCreators(selectStore, dispatch),
    changeSelectDate: bindActionCreators(allIndexActions.changeSelectDate, dispatch),
    changeSelectStore: bindActionCreators(changeSelectStore, dispatch),
    track: bindActionCreators(track, dispatch),
    fetchYearStoreList: bindActionCreators(apiActions.fetchYearStoreList, dispatch),
    fetchYearAnalysis: bindActionCreators(apiActions.fetchYearAnalysis, dispatch),
    changeDisplayedStoreList: bindActionCreators(changeDisplayedStoreList, dispatch),
    changeSelectIndicesType: bindActionCreators(changeSelectIndicesType, dispatch),
    changeSalesCustomizeConfigModalState: bindActionCreators(changeSalesCustomizeConfigModalState, dispatch),
    startFetchStoreSalesCustomizeItem: bindActionCreators(startFetchStoreSalesCustomizeItem, dispatch),
    startFetchSalesCustomizeItem: bindActionCreators(startFetchSalesCustomizeItem, dispatch),
    startPostSalesCustomizeConfig: config => dispatch(startPostSalesCustomizeConfig(config)),
    resetSalesCustomizeConfigModalState: bindActionCreators(resetSalesCustomizeConfigModalState, dispatch),
    toggleDialog: bindActionCreators(changeConfigDialogState, dispatch),
    startPostFiscalYearSettings: startMonth => dispatch(startPostFiscalYearSettings(startMonth)),
    closeFiscalYearSettingsMoal: () => dispatch(closeFiscalYearSettingsMoal()),
    changeFiscalYearSettingsModalState: bindActionCreators(changeFiscalYearSettingsModalState, dispatch),
    startFetchGroupFiscalYear: bindActionCreators(startFetchGroupFiscalYear, dispatch),
    resetStoreMonthlyApiState: bindActionCreators(apiActions.resetStoreMonthlyApiState, dispatch),
    clearBannerType: bindActionCreators(clearBannerType, dispatch),
    fetchKwsCandidate: bindActionCreators(fetchKwsCandidate, dispatch),
    fetchTaxSettingStatus: bindActionCreators(taxSettingStatusActions.fetchTaxSettingStatusStart, dispatch),
    fetchBasicSetting: bindActionCreators(fetchBasicSettingStart, dispatch),
    clearTaxSettingStatus: bindActionCreators(clearTaxSettingStatus, dispatch),
  };
};

const MainWrapper = styled.div`
  display: flex;
  height: calc(100% - 10px);
  padding-bottom: 24px;
`;
const StoreDataContainer = styled.div<{ hasStoreList: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  ${props =>
    props.hasStoreList
      ? `
      width: calc(100% - 235px);
      `
      : 'width: 100%;'}
`;
const ContentContainer = styled.div`
  height: 100%;
  width: 100%;
`;
const ContentWrapper = styled.div<{ hasStoreList: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: ${props => (!props.hasStoreList ? '24px;' : '0px 24px 0px 0px;')};
`;
const LinkText = styled.a`
  color: ${airblue};
`;

const Annotation = styled.div`
  font-size: 10px;
  white-space: pre-wrap;
  color: ${disabledTextColor};
`;

const StyledExternalLink = styled(ExternalLink)`
  margin-left: 4px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StoreContainer));

const _genHandleClickStoreListItemLog = (akrCode, storeName, indicesType: keyof typeof INDICES_TYPE) => {
  return genGaLog(
    'store_indices',
    'store_list',
    'select_store',
    { store_name: storeName },
    { selected_akr_code: akrCode, selected_indices_type: INDICES_TYPE[indicesType].logName },
    'click'
  );
};

const _genChangeDisplayedStoreListLog = (toggleState: boolean) => {
  return genGaLog(
    'store_indices',
    'store_list_toggle',
    'change_displayed_store_list_toggle',
    {},
    { toggleState },
    'click'
  );
};

const _genConfigModalLoadLog = () => {
  return genGaLog('store_indices', 'indices_customize_modal', 'on_load', {}, {}, 'load');
};

const _genConfigModalCloseLog = () => {
  return genGaLog('store_indices', 'indices_customize_modal', 'close', {}, {}, 'click');
};

const _genConfigModalSubmitLog = () => {
  return genGaLog('store_indices', 'indices_customize_modal', 'submit_indices_customize', {}, {}, 'click');
};

const _genFiscalYearSettingModalLoadLog = () => {
  return genGaLog('store_indices', 'fiscal_year_setting_modal', 'on_load', {}, {}, 'load');
};

const _genFiscalYearSettingModalSubmitLog = () => {
  return genGaLog('store_indices', 'fiscal_year_setting_modal', 'submit_fiscal_year', {}, {}, 'click');
};

const _genCloseFiscalYearModalLog = () => {
  return genGaLog('store_indices', 'fiscal_year_setting_modal', 'close', {}, {}, 'click');
};

const _genOpenSettingLog = () => {
  return genGaLog('store_indices', 'Indices_setting_modal', 'open_setting', {}, {}, 'click');
};

const _genChangeStoreLog = (akrCode, storeName, indicesType: keyof typeof INDICES_TYPE) => {
  return genGaLog(
    'store_indices',
    'select_box',
    'select_store',
    { store_name: storeName },
    { selected_akr_code: akrCode, selected_indices_type: INDICES_TYPE[indicesType].logName },
    'click'
  );
};

const _genClickWeatherLog = (akrCode: AkrCode.T) => {
  return genGaLog(
    'store_indices_monthly',
    'weather_description',
    'click_map_setting_link',
    {},
    { akrCode },
    'click'
  );
};
const _genTooltipFaqLog = (selectedIndicesType: keyof typeof INDICES_TYPE) => {
  const viewName = selectedIndicesType === 'daily' ? 'store_indices' : 'store_indices_monthly';
  return genGaLog(viewName, viewName, 'open_tooltip_lastUpdatedDateLabel_faq', {}, {}, 'click');
};
